/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('move-money', {
      parent: 'root',
      redirectTo: '.tabs.contact',
      url: '/move-money',
      template: '<ui-view />',
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('sections/move-money')
          );
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.move-money" */ 'sections/move-money'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('move-money.tabs', {
      redirectTo: '.contact',
      templateUrl: 'sections/move-money/templates/acp-move-money.ng.html'
    })
    .state('move-money.tabs.contact', {
      url: '/contact?id',
      template: '', // Would use the tab content except for bad Tab animation
      data: {
        permissions: {
          except: [
            'isCIPConditionalDocumentsNotUploaded',
            'isCIPConditionalDocumentsUploaded'
          ],
          redirectTo: {
            isCIPConditionalDocumentsNotUploaded: 'dashboard',
            isCIPConditionalDocumentsUploaded: 'dashboard'
          }
        }
      }
    })
    .state('move-money.tabs.send-flashpay-id', {
      url: '/send-flashpay-id',
      template: '', // Would use the tab content except for bad Tab animation
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/move-money',
              'page-title.receive-money'
            )
          );
        }
      }
    });
}

export default states;
